function isScrolledIntoView(elem) {
  return jQuery(window).scrollTop() + jQuery(window).height() - 100 >= jQuery(elem).offset().top;
  // var docViewTop = jQuery(window).scrollTop();
  // var docViewBottom = docViewTop + jQuery(window).height();

  // var elemTop = jQuery(elem).offset().top;
  // var elemBottom = elemTop + jQuery(elem).height();

  // return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
}

jQuery(window).scroll(function () {
  jQuery('.feature-content-wrapper').each(function () {
    if (isScrolledIntoView(this) === true) {
        jQuery(this).addClass('animate__animated animate__fadeInUp');
    }
  });
  jQuery('.order-1.feature-image-wrapper').each(function () {
    if (isScrolledIntoView(this) === true) {
        jQuery(this).addClass('animate__animated animate__fadeInLeft');
    }
  });
  jQuery('.order-5.feature-image-wrapper').each(function () {
    if (isScrolledIntoView(this) === true) {
        jQuery(this).addClass('animate__animated animate__fadeInRight');
    }
  });
  jQuery('.quote').each(function () {
    if (isScrolledIntoView(this) === true) {
        jQuery(this).addClass('animate__animated animate__fadeInUp');
    }
  });
});