var userInput = true;
jQuery(document).ready(function() {
    jQuery('#submitContactForm').on('click', function(e) {
        if(userInput) {
            e.preventDefault();
            formId = jQuery(this).closest('form').attr('action').split('-')[1].replace('f', '');
            if(validateForm(jQuery(this).closest('.contact-form'))) {
                userInput = false;
                jQuery('.wpcf7-submit').trigger('click');
            }
        }else {
            userInput = true;
        }
       
    });
});

function validateForm(form) {
    validateInput(false);
    var all_ok = true;
    form.find("input").each(function() {
        if(!validateInput(jQuery(this))) {
            all_ok = false;
            return false;
        }
    });
    return all_ok;
}

function validateInput(input) {
    if(input === false) {
        // Reset form response if invalid or sent
        jQuery('.invalid').removeClass('invalid').addClass('init');
        jQuery('.sent').removeClass('sent').addClass('init');
    }else
    if(input.attr('type') !== 'submit') {
        fieldName = input.attr('placeholder').toLowerCase();
        if(input.hasClass('wpcf7-validates-as-required') && !input.val().replace(/\s/g, '').length) {
            showError('The ' + fieldName + ' field is required');
            return false;
        }else if(input.hasClass('wpcf7-validates-as-email') && !isEmail(input.val())) { 
            showError('The email you provided is invalid');
            return false;
        }else if(input.hasClass('wpcf7-number') && !(/^\d+$/.test(input.val()))) {
            showError('The phone number you provided is invalid');
            return false;
        }
    }
    return true;
}

function isEmail(email) {
    var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email);
  }

function showError(errorMessage) {
    jQuery('.init').removeClass('init').addClass('invalid');
    output = jQuery('.wpcf7-response-output');
    output.text(errorMessage);
}