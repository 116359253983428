import { domReady } from "@roots/sage/client";

// Import jQuery
var $ = require("jquery");

// Import Bootstrap
import "bootstrap";

/**
 * app.main
 */
const main = async (err) => {
  if (err) {
    // handle hmr errors
    console.error(err);
  }

  // application code
};

/**
 * Initialize
 *
 * @see https://webpack.js.org/api/hot-module-replacement
 */
domReady(main);
import.meta.webpackHot?.accept(main);

// Import sections
import "./sections/_navbar";
import "./sections/_swiper";
import "./sections/_contact-form";
import "./sections/_animations";
import "./sections/_partner-cards";
import "./sections/_member";
