// import Swiper JS
import Swiper, { Navigation } from 'swiper';
Swiper.use([Navigation]);

const breakpoint = window.matchMedia('(min-width: 992px)');
let mySwiper

const breakpointChecker = function() {
    if(breakpoint.matches === true) {
        if(mySwiper !== undefined) mySwiper.destroy(true, true);
        return
    }else if(breakpoint.matches === false) {
        return enableSwiper();
    }
};

const enableSwiper = function() {
    mySwiper = new Swiper('.swiper', {
        // Optional parameters
        direction: 'horizontal',
        loop: true,
    
        // Navigation arrows
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    
        breakpoints: {  
       
            // when window width is <= 320px     
            320: {       
               slidesPerView: 1,
               spaceBetween: 10     
            },     
            // when window width is <= 480px     
            640: {       
               slidesPerView: 2,       
               spaceBetween: 20     
            },   
        
            // when window width is <= 640px     
            992: {       
               slidesPerView: 3,       
               spaceBetween: 30     
            } 
        
         } 
    
    });
};

breakpoint.addListener(breakpointChecker);
breakpointChecker();
