jQuery(document).ready(function ($) {
  $(".team-data a").each(function () {
    if ($(this).text().indexOf("https://") !== -1) {
      var text = $(this).text();

      // Remove https
      text = text.replace("https://", "");
      $(this).text(text);

      // Remove all slashes
      text = text.replace("/", "");
      $(this).text(text);
    }
  });
});
