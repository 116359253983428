const usernameLabel = document.querySelector(`label[for="username"]`);

if (usernameLabel) {
  // Create the <small> element for the extra text
  const extraText = document.createElement("small");
  extraText.textContent =
    " Only these characters are allowed: a-z 0-9 <space> _ . - @";

  // Create the <br> element
  const lineBreak = document.createElement("br");

  // Append the <small> element and <br> element to the label
  usernameLabel.appendChild(lineBreak);
  usernameLabel.appendChild(extraText);
}
