var activeMenu = null;
var timeout = null;
var opened = false;
var is_touch_device = "ontouchstart" in document.documentElement;
var elements = [];

jQuery(document).ready(function () {
  jQuery(".nav-btn").hover(function () {
    console.log("hoi");
    closeMenusHard();
  });
  /* Navigigation submenu logic */
  jQuery(".nav-link").on("mouseenter", function () {
    if (typeof jQuery(this).data("toggle") !== "undefined") {
      jQuery(".open").removeClass("open");
      if (timeout !== null) {
        clearTimeout(timeout);
        timeout = null;
      }
      if (jQuery(".active").length > 0) {
        jQuery(".active").removeClass("active");
        jQuery(this).addClass("active");
      } else {
        jQuery(this).addClass("active");
        jQuery(".nav-menus").removeClass("opacity-0");
        jQuery(".nav-menus").removeClass("d-none").addClass("d-flex");
      }
      var newMenu = jQuery("#" + jQuery(this).data("toggle"));
      if (
        (activeMenu !== null && activeMenu[0].id !== newMenu[0].id) ||
        activeMenu === null
      ) {
        newMenu.removeClass("d-none").addClass("d-flex");
        if (activeMenu !== null) {
          activeMenu.addClass("d-none").removeClass("d-flex");
        }
        activeMenu = newMenu;
      }
    }
  });
  jQuery(".menu-container").on("mouseleave", function () {
    closeMenus();
  });

  // On hamburger close
  jQuery(".navbar-toggler").on("click", function () {
    if (opened) {
      closeMenus();
      if (jQuery(window).scrollTop() <= 10) {
        elements.addClass("nav-white");
      }
      jQuery("body").removeClass("noscroll");
      opened = false;
    } else {
      elements.removeClass("nav-white");
      jQuery(".nav-menus").addClass("mobile-nav-overlay");
      jQuery("body").addClass("noscroll");
      opened = true;
    }
  });

  function closeMenus() {
    jQuery(".open").removeClass("open");
    jQuery(".nav-menus").addClass("opacity-0");
    jQuery(".active").removeClass("active");
    timeout = setTimeout(() => {
      jQuery(".nav-menus").removeClass("d-flex").addClass("d-none");
      if (activeMenu !== null) {
        activeMenu.addClass("d-none").removeClass("d-flex");
        activeMenu = null;
      }
    }, 200);
  }

  function closeMenusHard() {
    jQuery(".open").removeClass("open");
    jQuery(".nav-menus").addClass("opacity-0");
    jQuery(".active").removeClass("active");
    jQuery(".nav-menus").removeClass("d-flex").addClass("d-none");
  }
  /* ./Navigation submenu logic */

  /* Add nav-active class to parent items of active menu item */
  menuTitle = jQuery("li.current-menu-item")
    .closest(".menu")
    .find(".menu-title");
  menuTitle.addClass("nav-active");
  jQuery("nav.navbar")
    .find(`[data-toggle='${menuTitle.parent().parent().attr("id")}']`)
    .addClass("nav-active");
});

// Make dropdown work on mobile devices
jQuery(document).ready(function () {
  jQuery(".nav-link").on("click", function (e) {
    if (
      typeof jQuery(this).data("toggle") !== "undefined" &&
      is_touch_device &&
      !jQuery(this).hasClass("open")
    ) {
      jQuery(this).addClass("open");
      e.preventDefault();
    }
  });
});

/* Scroll Logic */
var visible = false;
jQuery(document).ready(function () {
  elements = jQuery(".nav-white");
  jQuery(window).on("scroll", function () {
    if (is_touch_device && opened) return;
    if (jQuery(window).scrollTop() > 10) {
      if (!visible) {
        visible = true;
        elements.removeClass("nav-white");
      }
    } else {
      if (visible) {
        visible = false;
        elements.addClass("nav-white");
      }
    }
  });
});

function trp_dropdown_menu_toggle() {
  document
    .getElementById("mytranslate-press-dropdown")
    .classList.toggle("translate-press-dropdown-show");
}
